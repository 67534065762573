exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/blog-post-template.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/tag-template.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */)
}

